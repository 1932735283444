import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../components/layout/layout'

const createMarkup = (content) => {
  return {__html: content}
}

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>{`${data.project.name} - ${data.site.siteMetadata.title}`}</title>
    </Helmet>
    <div id='main'>
      <section id='projects'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='global-page-header text-center'>
                <div className='clearfix'>
                  <h2>{data.project.name}</h2>
                  <div dangerouslySetInnerHTML={createMarkup(data.project.description)}></div>
                  <p>Copyright © Tiki</p>
                  <p>All Rights Reserved</p>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              {data.file &&
                <div className='project-item-show'>
                  <Img 
                    alt={data.project.name}
                    title={data.project.name}
                    fluid={data.file.childImageSharp.fluid} 
                  />
                </div>
              }
              <div className='line-bottom text-center'>
                <Link to={`/project/${data.project.next}/`} className='btn btn-primary'>Next project</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export const query = graphql`
  query ProjectDetails($projectId: String!, $projectSlug: String!) {
    project(id: {eq: $projectId}) {
      id
      name
      description
      next
    }
    file(name: {eq: $projectSlug}) {
      id
      childImageSharp {
        fluid(quality: 90, maxWidth: 1250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
